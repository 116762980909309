import React from "react"
import { Figure } from "../MainImage"
import H2 from "../Objects/H2"
import AnimationComponent from "../AnimationComponent"
import classNames from "classnames"

const ProductHeader = ({ tagline, header, image, description, ...props }) => {
  return (
    <div className="py-4 border-t border-secondary w-11/12 lg:w-10/12 mx-auto  text-secondary my-10">
      {tagline && (
        <p className="font-medium text-xl text-secondary">{tagline}</p>
      )}{" "}
      <div className={classNames("py-0", !image && "py-10")}>
        {image && (
          <div className=" my-18">
            <Figure
              node={image}
              className="w-full h-full aspect-[1292/591] object-cover "
            />
          </div>
        )}
        {header && (
          <h2 className="font-medium text-2xl lg:text-4xl text-secondary mb-10">
            {header}
          </h2>
        )}
        {description && (
          <p className=" text-xl  lg:text-3xl max-w-2xl ">{description}</p>
        )}
      </div>
    </div>
  )
}

export default ProductHeader
