import React from "react"
import { graphql } from "gatsby"
import SliderSection from "../components/home/sliderSection"
import SolutionsRows from "../components/home/solutionsRows"
import ProjectsHome from "../components/home/projectsHome"
import ClientsHome from "../components/home/clientsHome"
import PricingPlans from "../components/PricingSection/index"
import FeaturesSection from "../components/FeaturesSection"
import FaqSection from "../components/FaqSection"
import PartnerSection from "../components/PartnerSection"
import Story from "../components/Story"
import PillarsSection from "../components/PillarsSection"
import Banner from "../components/Banner"
import HeroSection from "../components/HeroSection"
import QuoteWithImage from "../components/QuoteWithImage"
import ClientSection from "../components/ClientSection"
import QuotesCards from "../components/QuotesCards"
import PartnersCircle from "../components/PartnersCircle"
import StickySection from "../components/StickySection"
import Seo from "../components/seo"
import ContactUs from "../components/ContactUs"
import EmailUs from "../components/EmailUs"
import Steps from "../components/Steps"
import LandingSections from "../components/LandingSection"
import { JoinTeamComponent } from "../components/JoinTeamComponent"
import PricingHome from "../components/home/pricingHome"
import SolutionsGrid from "../components/ProductComponents/solutionsGrid"
import ProductHeader from "../components/ProductComponents/headerProduct"
import FeaturesWithImageProduct from "../components/ProductComponents/featuresImageProduct"
import FeaturesGridProduct from "../components/ProductComponents/FeaturesGridProduct"
import KeyFeaturesProduct from "../components/ProductComponents/keyFeaturesProduct"
import KeyFeaturesList from "../components/ProductComponents/keyFeaturesList"

export const query = graphql`
  query ProductTemplateQuery($id: String!) {
    sanityProduct(id: { eq: $id }) {
      slug {
        current
      }
      _rawContent(resolveReferences: { maxDepth: 10 })
      title
    }
  }
`

const Product = ({ data }) => {
  const product = data.sanityProduct
  const content = (product._rawContent || []).map((c, i) => {
    let el = null
    switch (c._type) {
      case "SliderSection":
        el = <SliderSection {...c} />
        break
      case "solutionsGridProduct":
        el = <SolutionsGrid key={c._key} {...c} />
        break
      case "headerProduct":
        el = <ProductHeader key={c._key} {...c} />
        break
      case "featuresGridProduct":
        el = <FeaturesGridProduct key={c._key} {...c} />
        break
      case "featuresWithImageProduct":
        el = <FeaturesWithImageProduct key={c._key} {...c} />
        break
      case "keyFeaturesProduct":
        el = <KeyFeaturesProduct key={c._key} {...c} />
        break
      case "keyFeaturesList":
        el = <KeyFeaturesList key={c._key} {...c} />
        break
      case "projectsHome":
        el = <ProjectsHome key={c._key} {...c} />
        break
      case "clientsHome":
        el = <ClientsHome key={c._key} {...c} />
        break

      case "faqSection":
        el = <FaqSection key={c._key} {...c} />
        break
      case "partnerSection":
        el = <PartnerSection key={c._key} {...c} />
        break
      case "story":
        el = <Story key={c._key} {...c} />
        break
      case "pillars":
        el = <PillarsSection key={c._key} {...c} />
        break
      case "statsBanner":
        el = <Banner key={c._key} {...c} />
        break
      case "heroes":
        el = <HeroSection key={c._key} {...c} />
        break
      case "quoteWithImage":
        el = <QuoteWithImage key={c._key} {...c} />
        break
      case "clientSection":
        el = <ClientSection key={c._key} {...c} />
        break
      case "quotesCards":
        el = <QuotesCards key={c._key} {...c} />
        break
      case "partnersCircle":
        el = <PartnersCircle key={c._key} {...c} />
        break
      case "stickySections":
        el = <StickySection key={c._key} {...c} />
        break
      case "contactUs":
        el = <ContactUs key={c._key} {...c} />
        break
      case "emailUs":
        el = <EmailUs key={c._key} {...c} />
        break
      case "steps":
        el = <Steps key={c._key} {...c} />
        break
      case "landingSections":
        el = <LandingSections key={c._key} {...c} />
        break
      case "uiComponentRef":
        switch (c.name) {
          case "joinTeam":
            return <JoinTeamComponent key={c._key} {...c} />
            break
          default:
            break
        }
      default:
        el = null
    }

    return el
  })
  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `
          (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "k7vzhz0vn2");
        `,
        }}
      />

      <Seo title={product.title} />
      {content}
      <EmailUs />
    </>
  )
}

export default Product
