import React from "react"
const KeyFeaturesList = ({ tagline, title, Features }) => {
  return (
    <section className="bg-[#EEF5FD] py-12">
      <div className="w-11/12 lg:w-10/12 mx-auto py-4 border-t border-secondary">
        {tagline && (
          <p className=" text-xl text-secondary font-light">{tagline}</p>
        )}{" "}
        <div className="py-20">
          <div className=" ">
            <div>
              {title && (
                <h2 className="text-secondary text-4xl font-medium  mb-4 lg:mb-12 ">
                  {title}
                </h2>
              )}

              <ul className=" grid w-full gap-4 lg:gap-20 lg:grid-cols-2">
                {Features &&
                  Features.map(({ title, points }, index) => {
                    return (
                      <>
                        <li
                          className="flex flex-col  text-secondary "
                          key={index++}
                        >
                          {title && (
                            <h2 className="text-secondary text-3xl  mb-6 ">
                              {title}
                            </h2>
                          )}

                          {points && (
                            <ul className=" grid  w-full gap-4 lg:gap-10">
                              {points.map((text, index) => {
                                return (
                                  <li
                                    className="flex gap-4 lg:gap-8  text-secondary "
                                    key={index++}
                                  >
                                    <div className=" mt-2 border w-5 h-5 text-xl flex justify-center items-center   border-secondary rounded-full flex-shrink-0 flex-none"></div>
                                    <p className="lg:text-xl">{text}</p>
                                  </li>
                                )
                              })}
                            </ul>
                          )}
                        </li>
                      </>
                    )
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default KeyFeaturesList
