import React from "react"
import { Figure } from "../MainImage"
const FeaturesGridProduct = ({
  tagline,
  title,
  description,
  Features,
  image,
}) => {
  return (
    <section className="  bg-[#EEF5FD] py-12 ">
      <div className="w-11/12 lg:w-10/12 mx-auto py-4 border-t border-secondary">
        {tagline && (
          <p className="font-medium text-xl text-secondary">{tagline}</p>
        )}{" "}
        <div className="py-20">
          <div className=" ">
            <div>
              {title && (
                <h2 className="text-secondary text-3xl  mb-4 lg:mb-8 ">
                  {title}
                </h2>
              )}{" "}
              {description && (
                <p className=" md:text-3xl text-secondary mb-10 lg:mb-20 max-w-3xl">
                  {description}
                </p>
              )}
              <ul className=" grid lg:grid-cols-2 w-full gap-6 lg:gap-20">
                {Features &&
                  Features.map(({ title, text }, index) => {
                    return (
                      <li
                        className="flex gap-4 lg:gap-8  text-secondary "
                        key={index++}
                      >
                        <div className="  border w-8 h-8 lg:w-12 lg:h-12 lg:text-xl flex justify-center items-center   border-secondary rounded-full flex-shrink-0 flex-none">
                          {index++}
                        </div>
                        <div className="max-w-md">
                          <h3 className="text-secondary md:text-2xl  mb-4">
                            {title}
                          </h3>
                          <p>{text}</p>
                        </div>
                      </li>
                    )
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default FeaturesGridProduct
