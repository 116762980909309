import React from "react"
import { Figure } from "../MainImage"
const FeaturesWithImageProduct = ({ title, description, Features, image }) => {
  return (
    <section className="  ">
      <div className="w-11/12 lg:w-10/12 mx-auto">
        {/* {title && <p className="font-medium text-xl text-secondary">{title}</p>}{" "} */}
        <div className="py-20">
          <div className=" grid lg:grid-cols-2 gap-20 place-items-center">
            <div className=" row-start-1 row-end-2 lg:row-auto w-full h-full">
              {image && (
                <figure className="w-full h-full aspect-square">
                  <Figure
                    node={image}
                    className=" object-cover w-full h-full "
                  />
                </figure>
              )}
            </div>
            <div>
              {title && (
                <h2 className="text-secondary text-3xl  mb-4 lg:mb-8 ">
                  {title}
                </h2>
              )}{" "}
              {description && (
                <p className=" md:text-xl text-secondary mb-4 lg:mb-8">
                  {description}
                </p>
              )}
              <ul className="flex flex-col gap-6">
                {Features &&
                  Features.map(({ title, text }, index) => {
                    return (
                      <li className="flex gap-3   " key={index++}>
                        <div className="  border w-8 h-8 flex justify-center items-center   border-secondary rounded-full flex-shrink-0 flex-none">
                          {index++}
                        </div>
                        <div>
                          {title && <h2 className="mb-2 text-xl">{title}</h2>}
                          {text && (
                            <p className="text-secondary md:text-xl ">{text}</p>
                          )}{" "}
                        </div>
                      </li>
                    )
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default FeaturesWithImageProduct
