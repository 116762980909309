import React from "react"
import { Figure } from "../MainImage"
import classNames from "classnames"
import PortableText from "../portableText"
const Card = ({ tagline, image, title, pageLink, simpleBlockContent }) => {
  return (
    <li className="">
      <div className=" flex flex-col  item">
        <figure className="w-full h-full flex  mb-4  ">
          {image && image.asset && (
            <Figure
              className=" aspect-square max-h-64 w-fit"
              node={image}
              alt={title}
            />
          )}
        </figure>
        {title && (
          <h3 className=" text-lg text-secondary font-medium ">{title}</h3>
        )}
        {simpleBlockContent && (
          <p className=" text-secondary font-light mt-3">
            <PortableText blocks={simpleBlockContent} />
            {/* {simpleBlockContent} */}
          </p>
        )}
      </div>
    </li>
  )
}
const SolutionsGrid = props => {
  const { tagline, header, solutions } = props
  return (
    <div className="w-11/12 lg:w-10/12 py-4 border-t border-secondary mx-auto my-20">
      {tagline && (
        <p className="font-medium text-xl text-secondary">{tagline}</p>
      )}{" "}
      <div className="  mx-auto py-20 ">
        {header && (
          <h2 className="font-Poppins text-2xl mb-10  lg:text-4xl lg:mb-20 text-secondary max-w-4xl">
            {header}
          </h2>
        )}{" "}
        <ul
          className={classNames(
            "grid gap-4 lg:gap-8  w-full",
            solutions.length === 3 && "lg:grid-cols-3",
            solutions.length === 4 && "lg:grid-cols-4",
            solutions.length > 4 && "lg:grid-cols-3"
          )}
        >
          {solutions?.map(
            ({ image, title, simpleBlockContent, _key, ...props }, index) => {
              console.log(props, "props")
              return (
                <Card
                  image={image}
                  title={title}
                  simpleBlockContent={simpleBlockContent}
                  _key={_key}
                  {...props}
                />
              )
            }
          )}
        </ul>
      </div>
    </div>
  )
}
export default SolutionsGrid
